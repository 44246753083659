import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })

export class SharedService {

    private invokeMethodSourceFT = new Subject<void>();    
    invokeMethodFT$ = this.invokeMethodSourceFT.asObservable();

    private invokeMethodSource = new Subject<void>();    
    invokeMethod$ = this.invokeMethodSource.asObservable();

    private requestTypeUpdateSource = new Subject<void>();
    requestTypeUpdated$ = this.requestTypeUpdateSource.asObservable();

    // this method is being used for communication between "tasks" component and "request" component
    // The dialog for task is closed, an observable on "request" will be triggered
    // to "refreshData"
    reloadAFT() {
        this.invokeMethodSourceFT.next();
    }    

    // this method is being used for communication between "agent" component and "filter" component
    // The moment a custom filter is deleted, an observable on "agent" will be triggered
    // to re-list the filters on the left side so that it will show an updated information
    reloadAgentFilters() {
        this.invokeMethodSource.next();
    }    

    // This method will notify other components when request types are updated
    reloadRequestTypes() {
        this.requestTypeUpdateSource.next();
    }
}
