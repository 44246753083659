import { HostListener, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { TasksApiService } from 'src/app/services/api/tasks/tasks-api-service.service';
import { ApiAdminGroupsService } from 'src/app/services/api/admin/admin-groups-api/admin-groups-api.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Dialog } from 'src/app/shared/dialog/dialog';
import { RequestComponent } from '../request.component';
import { OptionsApiService } from 'src/app/services/api/options/options-api.service';
import { TasksComponent } from '../../tasks/tasks.component';
import { RequestApiService } from 'src/app/services/api/request/request-api.service';
import { ApiService } from 'src/app/services/api/api.service';

@Component({
  selector: 'app-request-creat-edit-dialog.component',
  templateUrl: './request-creat-edit-dialog.component.component.html',
  styleUrl: './request-creat-edit-dialog.component.component.css'
})
export class RequestCreatEditDialogComponentComponent implements Dialog {
  @Input() data: any;
  @Output() remove = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<boolean>();
  @ViewChild(TasksComponent,{ static: false }) tasksComponent: TasksComponent;
  @ViewChild('instructionsTextarea', { static: false }) instructionsTextarea: ElementRef;


  loadedData: any = {
    TaskName: '',
    Instructions: '',
    RequestId: null,
    TypeId: null,
    GroupId: null,
    AgentId: null,
    LocalId: null,
    Completed: false,
    SendCompletionEmail: false,
    // ShowCompletionEmailDialog: false,
    CompletionEmailContents: '',
    Status: 'pending',
    Comment: ''
  };
    // loadedData;
    // Timer
    timer_enabled = false;
    timer_start = null;
    timer_manual = false;
  
    timer_manual_Hours = 0;
    timer_manual_Minutes = 0;

  groups;
  agents;
  isLoading = true;
  options;
  environmentParams;
  isLoadingOptions:true;
  status;
  comment;
  commentArray = [];
  isCommentSaving=false;
  savedtaskId;

  statusData: boolean = false;

  // Status options
  statuses = [
    { value: 0, label: 'Pending'},
    { value: 1, label: 'Completed'}
  ];

  constructor(
    private tasksApiService: TasksApiService,
    private apiAdminGroupsService: ApiAdminGroupsService,
    private environmentService: EnvironmentService,
    private apiOptions: OptionsApiService,
    private requestApi: RequestApiService,
    private api: ApiService
  ) {
    //get the data before the to the popup(request id and taskid in the data array)
    this.environmentService.environmentVariablesSubscription.subscribe(data => this.environmentParams = data);
  }

  loadStatusData(taskId: number) {
    if (!taskId) return;

    this.tasksApiService.getTask(taskId)
      .then(response => {
        this.statusData = response.Completed === 1;
        console.log('Task status loaded:', this.statusData);
      })
      .catch(error => {
        console.error('Error loading task status:', error);
        this.statusData = false;
      });
  }

  ngOnInit(): void {
    if (this.data) {
      if (this.data["taskId"]!=null) {
          this.loadedData = { ...this.loadedData, Id: this.data["taskId"] };
        // Edit mode
        this.loadStatusData(this.loadedData.Id);
          this.loadTaskData(this.loadedData.Id);
          this.status=this.availableStatuses();
          this.getComment(this.loadedData.Id);
      } else {
        // Create mode
        this.loadedData = {
          ...this.loadedData,
          Id: null,
          RequestId:this.data["requestId"]
        };
        this.refreshOptions();
        this.loadedData.Completed=0;
        this.isLoading = false;
      }
    }
    this.timer_CheckEnabled();
  }

  loadAgents(groupId: number) {
    if (!groupId) return;
  
    this.apiAdminGroupsService.get(groupId)
      .then(response => {
        if (response && response.Members) {
          this.agents = response.Members.map(member => ({
            id: member.Id,
            name: member.Name
          }));
        }
      })
      .catch(error => {
        console.error('Error loading agents:', error);
        this.agents = [];
      });
  }

  loadGroups() {
    this.apiAdminGroupsService.listAll()
      .then(response => {
        this.isLoading = false;
      })
      .catch(error => {
        console.error('Error loading groups:', error);
        this.isLoading = false;
      });
  }

  isCmdbEnabled(): boolean {
    return this.loadedData.CmdbEnabled === 1;
  }

  timer_CheckEnabled() {
    this.api.get("/api/timer/settings").then(response => {
      console.log(response);
      if (response.TimeTrackingEnabled && !this.isCmdbEnabled())  {
        this.timer_enabled = true;
        this.timer_StartTimer();
      }
    })
  }

  timer_ResetTimer() {
    if (this.timer_enabled) {
      this.timer_StartTimer();
      this.timer_manual = false;
      this.timer_manual_Hours = 0;
      this.timer_manual_Minutes = 0;
    }
  }

  timer_StartTimer() {
    console.log("Starting Timer");
    this.timer_start = new Date();

    setInterval(() => {
      this.timer_start = this.timer_start;
    }, 1000);

  }

  sendEmailAndComplete() {
    if (!this.loadedData.CompletionEmailContents) {
      return;
    }
    this.isLoading = true;
    this.requestApi.threadReply(
      this.loadedData.RequestId, 
      this.loadedData.CompletionEmailContents, 
      [], 
      1, 
      null, 
      false
    )
    .then(() => {
      return this.tasksApiService.markComplete(this.loadedData.Id);
    })
    .then(() => {
      this.refreshData.emit(true);
      this.close();
    })
    .catch(error => {
      console.error('Error in completion process:', error);
      this.isLoading = false;
    });
  }

  loadTaskData(taskId: number) {
    this.tasksApiService.getTask(taskId)
      .then(response => {
        this.loadedData = response;
        console.log("response",this.loadedData);
        this.refreshOptions();
        this.isLoading = false;
        setTimeout(() => this.adjustTextareaHeight(), 0); // Adjust height after data is loaded
      })
      .catch(error => {
        console.error('Error loading task:', error);
        this.isLoading = false;
      });
  }

  availableStatuses() {
    if (!this.data?.id) {
      return [{ value: 'pending', label: 'Pending' }];
    }
    return this.statuses;
  }

  onGroupChange(event: any) {
    // Reset agent when group changes
    this.loadedData.AgentId = '';
    if (event) {
      this.refreshOptions()
    } else {
      this.loadedData.GroupId = null;
      this.agents = [];
    }
  }

  refreshOptions() {
    if(this.data['typeId']){
      this.apiOptions.DynamicOptions(this.data['typeId'], this.loadedData.GroupId,this.loadedData.CustomerId)
      .then(r => {
        if (r && r.group) {
          this.groups = r.group.map(item => ({
            id: item.id,
            name: item.name
          }));
          this.agents = r.agent.map(item => ({
            id: item.id,
            name: item.name
          }));
        }

      });
    }

  }
  onStatusChange(event: any) {
    if (event === 1 && this.loadedData.SendCompletionEmail) {
      this.loadedData.Completed = 1;
    } else if (event === 1 && !this.loadedData.SendCompletionEmail) {
      this.loadedData.Completed = 1;
      this.statusData = true;
    } else {
      this.statusData = false;
    }
  }

  save() {
    this.isLoading = true;

    // Check if TaskName is empty
    if (!this.loadedData.TaskName || this.loadedData.TaskName.trim() === '') {
      this.isLoading = false;
      this.tasksApiService.create(this.loadedData)
      return; 
    }
  
    // Ensure an agent is assigned before marking the task as complete
    if (this.loadedData.Completed == 1 && !this.loadedData.AgentId) {
      this.isLoading = false; 
      this.tasksApiService.markComplete(this.loadedData.Id);
      return; 
    }
    
    if (this.loadedData?.Id){
      // Update existing task
      this.tasksApiService.updateTask(this.loadedData.Id, this.loadedData)
      .then(() => {
        // Assign to Group first
        if (this.loadedData.GroupId) {
          return this.tasksApiService.assignToGroup(this.loadedData.Id, this.loadedData.GroupId);
        }
      })
      .then(() => {
        // Assign to Agent second
        if (this.loadedData.AgentId) {
          return this.tasksApiService.assignToAgent(this.loadedData.Id, this.loadedData.AgentId);
        } else {
          return this.tasksApiService.unassignAgent(this.loadedData.Id);
        }
      })
      .then(() => {
        // Mark as Complete or Pending last
        if (this.loadedData.Completed == 1 ) {
          if (!this.loadedData.SendCompletionEmail) {
          return this.tasksApiService.markComplete(this.loadedData.Id);
          }
        } else {
          return this.tasksApiService.markPending(this.loadedData.Id);
        }
      })
      .then(() => {
        // Ensure refreshData runs after all other operations
        this.refreshData.emit(true);
        if (this.tasksComponent) {
          this.tasksComponent.isLoading = false;
        }
        this.close();
      })
      .catch((error) => {
        // Handle errors if needed
        console.error("Error in task processing:", error);
        if (this.tasksComponent) {
          this.tasksComponent.isLoading = false;
        }
        this.refreshData.emit(true);
        return this.tasksApiService.markPending(this.loadedData.Id);
        this.close();
      }).finally(()=>{
        this.close();
      });
    } else {
      // Create new task
      this.tasksApiService.create(this.loadedData)
        .then(taskId => {
          this.savedtaskId = taskId; 
          if (this.loadedData.GroupId) {
            return this.tasksApiService.assignToGroup(taskId, this.loadedData.GroupId)
              .then(() => taskId);
          }
          return taskId;
        })
        .then(taskId => {
          if (this.loadedData.AgentId) {
            this.tasksApiService.assignToAgent(taskId, this.loadedData.AgentId);
          }else{
            this.tasksApiService.unassignAgent(taskId);
          }
        })
        .then(() => {
          if(this.commentArray){
            this.isCommentSaving =true;
            this.addComment();
          }
          this.refreshData.emit(true);
          if (this.tasksComponent) {
            this.tasksComponent.isLoading = false;
          }
          this.close();
        })
        .catch(error => {
          console.error('Error creating task:', error);
          if (this.tasksComponent) {
            this.tasksComponent.isLoading = false;
          }
          this.close();
        })
        .finally(() => {
          this.close();
        });
    }
  }

  timer_ElapsedMilliseconds() {
    if (!this.timer_start) {
      return 0;
    }
    // @ts-ignore
    var elapsed = new Date() - this.timer_start;
    return elapsed;
  }

  timer_ElapsedSeconds() {
    if (this.timer_manual) {
      let elapsedSeconds = 0;
      elapsedSeconds = elapsedSeconds + this.timer_manual_Hours * 60 * 60;
      elapsedSeconds = elapsedSeconds + this.timer_manual_Minutes * 60;
      return elapsedSeconds;
    }
    return this.timer_ElapsedMilliseconds() / 1000;
  }

  addComment() {
    // comment before creating the task
    if(!this.loadedData.Id){
      // save the comments that create before the task
      if(this.isCommentSaving){
        const commentsArray = this.commentArray.filter(item => item.notsaved===true).map(item => ({
          TaskId: this.savedtaskId,
          Comment: item.BodyText,
          SecondsElapsed: item.TimeTrackingSeconds
        }));
        console.log("the data1", commentsArray)
        this.requestApi.threadCreateTaskComment(this.loadedData.Id, commentsArray)
              .then(response => {
                  this.refreshData.emit(true); // Emit the event to refresh the data
                  this.loadedData.Comment = ''; // Clear the comments
                  this.getComment(this.loadedData.Id);
              })
              .catch(error => {
                  console.error('Error adding comment:', error);
              });

      }
      //collect the comments that created before the task creation
      else{
          if (this.loadedData && this.loadedData.Comment) {
            // this.commentArray = []
            if (Array.isArray(this.loadedData.Comment)) {
              // Map each comment to include SecondsElapsed
              this.commentArray = this.loadedData.Comment.map((comment, index)=> ({
                BodyText: comment,
                ThreadType:5,
                notsaved:true,
                TimeTrackingSeconds: this.timer_ElapsedSeconds(),
                Index: this.commentArray.length + index // Add the index here
              }));
            } else {
              // Push single object
              this.commentArray.push({
                BodyText: this.loadedData.Comment,
                ThreadType:5,
                notsaved:true,
                TimeTrackingSeconds: this.timer_ElapsedSeconds(),
                Index: this.commentArray.length // Assuming this is the first item
              });
            }
          }
          this.loadedData.Comment=null;
      }

    }else{
      // comment after create the task
      const commentsArray = [{
        TaskId: this.loadedData.Id,
        Comment: this.loadedData.Comment,
        SecondsElapsed: this.timer_ElapsedSeconds()
    }];

      this.requestApi.threadCreateTaskComment(this.loadedData.Id, commentsArray)
            .then(response => {
                this.refreshData.emit(true); // Emit the event to refresh the data
                this.loadedData.Comment = ''; // Clear the comments
                this.getComment(this.loadedData.Id);
            })
            .catch(error => {
                console.error('Error adding comment:', error);
            });
    }
    
  }

  async getComment(taskId) {
    try {
      if(taskId){
        const response = await this.requestApi.threadGetTaskComment(taskId);
        // this.requestApi.getThread(this.loadedData.TypeId);
        this.comment = response; // Assuming response is the comment
      }
        console.log('comment',this.comment);
    } catch (error) {
        console.error('Error fetching comment:', error);
    }
  }

  close() {
    this.commentArray=[];
    this.remove.emit();
  }

  // Function to adjust the height of the textarea
  adjustTextareaHeight() {
    const textarea = this.instructionsTextarea.nativeElement;
    textarea.style.height = 'auto'; 
    textarea.style.height = textarea.scrollHeight + 'px';
  }

  @HostListener('input', ['$event.target'])
  onInput(textarea: HTMLTextAreaElement) {
    this.adjustTextareaHeight();
  }

  ngAfterViewInit() {
    this.adjustTextareaHeight();
  }
}