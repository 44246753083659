import {Injectable} from '@angular/core';
import {ApiService} from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class OptionsApiService {

  constructor(private api: ApiService) {
  }

  requestTypeApi = '/api/options/request/type';
  dynamicOptionsApi = '/api/itil/request/options/type'

  AllRequestTypes() {
    return this.api.get(this.requestTypeApi);
  }

  AllRequestTypesNav(){
    return this.api.get('/api/options/request/navigation-types');
  }

  DynamicOptions(typeId, groupId = null, customerId = null) {
    let requestBody = {
      GroupId: groupId,
      CustomerId: customerId
    }
    return this.api.post(`${this.dynamicOptionsApi}/${typeId}/v2`, requestBody)
  }

  DynamicOptionsSecurityGroup(typeId, groupId = null, customerId = null) {
    let requestBody = {
      GroupId: groupId,
      CustomerId: customerId
    }
    return this.api.post(`${this.dynamicOptionsApi}/${typeId}/v3`, requestBody)
  }

  Filteragents(typeId=null, groupId = null, customerId = null) {
    let requestBody = {
      GroupId: groupId,
      CustomerId: customerId
    }
    return this.api.post(`${this.dynamicOptionsApi}/${typeId}/v2`, requestBody)
  }
  // only use for the ticket view
  RequestOptions(requestId) {
    let requestOptionsApi = `/api/itil/request/${requestId}/options`;
    return this.api.get(requestOptionsApi);
  }

  AllOptions() {
    let allOptionsApi = `/api/options`;
    return this.api.get(allOptionsApi);
  }

  AllAgents(){
    let allAgentsApi = `/api/agent/all`;
    return this.api.get(allAgentsApi);
  }

  AllCustomers() {
    let allOptionsApi = `/api/options/customer`;
    return this.api.get(allOptionsApi);
  }

  ServiceSearchOptions(customerId, customerLocationId) {
    return this.api.get('/api/servicesearch', {
      'CustomerId': customerId,
      'CustomerLocationId': customerLocationId
    })
  }
}
