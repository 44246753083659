import {Component, EventEmitter, Input, OnInit, Output, ViewContainerRef} from '@angular/core';
import {RequestApiService} from '../../../../services/api/request/request-api.service';
import {OptionsApiService} from '../../../../services/api/options/options-api.service';
import {TasksApiService} from '../../../../services/api/tasks/tasks-api-service.service';
import {DialogService} from '../../../../services/dialog-service/dialog.service';
import {TasksDialogComponent} from '../tasks-dialog/tasks-dialog.component';
import {ModalConfirmationComponent} from '../../../../shared/modal-confirmation/modal-confirmation.component';
import {SelectionModel} from '@angular/cdk/collections';
import { ApiAdminGroupsService } from 'src/app/services/api/admin/admin-groups-api/admin-groups-api.service';
import { SdeskHelper } from 'src/app/shared/helper/SdeskHelper';

@Component({
  selector: 'app-all-tasks',
  templateUrl: './all-tasks.component.html',
  styleUrls: ['./all-tasks.component.css']
})
export class AllTasksComponent implements OnInit {

  @Input() requestId = null;
  @Output() refreshParentComponent = new EventEmitter<any>();

  // Filter
  filterGroupIds = new SelectionModel(true,[]);
  filterAgentIds = new SelectionModel(true,[]);
  filterAgentIdsIncludeUnassigned = true;
  filterGroupIdsIncludeUnassigned = true;

  data;
  options;
  isLoading = true;
  isLoadingOptions = true;
  isGroupsDropdownVisible: boolean;
  isAgentsDropdownVisible: boolean;
  groupSearch: String = '';
  filteredGroups:any[] = [];
  agentSearch: String = '';
  filteredAgents:any[] = [];

  static savedGroupIds: any[]= [];
  static savedAgentIds:any[]= [];
  static savedAgentIdsIncludeUnassigned: boolean = true;
  static savedGroupIdsIncludeUnassigned: boolean = true;

  constructor(private requestApi: RequestApiService, private optionsApi: OptionsApiService,private apiAdminGroupsService: ApiAdminGroupsService,
 private tasksApiService: TasksApiService, private dialogService: DialogService, private viewReference: ViewContainerRef) {
  }

  ngOnInit(): void {
    this.optionsApi.AllOptions().then(response => {
      this.options = response;
      // Get the data from the cookie
      const savedGroupIds = this.getCookie('savedGroupIds');
      const savedAgentIds = this.getCookie('savedAgentIds');
      const savedAgentIdsIncludeUnassigned = this.getCookie('savedAgentIdsIncludeUnassigned');
      const savedGroupIdsIncludeUnassigned = this.getCookie('savedGroupIdsIncludeUnassigned');

      if (savedGroupIds) {
        AllTasksComponent.savedGroupIds = JSON.parse(savedGroupIds);
        AllTasksComponent.savedGroupIds.forEach(id => this.filterGroupIds.select(id));
      } else {
        this.options.group.forEach(group => {
          this.filterGroupIds.select(group.id);
        });
      }

      if (savedAgentIds) {
        AllTasksComponent.savedAgentIds = JSON.parse(savedAgentIds);
        AllTasksComponent.savedAgentIds.forEach(id => this.filterAgentIds.select(id));
      } else {
        this.options.agent.forEach(agent => {
          this.filterAgentIds.select(agent.id);
        });
      }

      this.filterAgentIdsIncludeUnassigned = savedAgentIdsIncludeUnassigned ? JSON.parse(savedAgentIdsIncludeUnassigned) : true;
      this.filterGroupIdsIncludeUnassigned = savedGroupIdsIncludeUnassigned ? JSON.parse(savedGroupIdsIncludeUnassigned) : true;

      this.filteredGroups = this.options.group;
      this.filteredAgents = this.options.agent;

      this.isLoadingOptions = false;
    });
    this.getData();
  }


  filterGroups(){

    if(!this.groupSearch)
      {
        this.filteredGroups = this.options.group
      }
      else{
        this.filteredGroups = this.options.group.filter(g=> g.name.toLowerCase().includes(this.groupSearch.toLowerCase()))
      }
  }

  filterAgent(){
    if(!this.agentSearch)
    {
      this.filteredAgents = this.options.agent
    }
    else{

      this.filteredAgents = this.options.agent.filter(a =>
        a.name.toLowerCase().includes(this.agentSearch.toLowerCase()));
    }
  }

  allTasksFiltered() {
    // Filter data based on GroupId
    let groupFilteredData = this.data.filter(task => {
        if (this.filterGroupIdsIncludeUnassigned) {
            // Include tasks:
            // 1. groupId is unassigned or null or
            // 2. group is in recover mode(==0 inactive) or
            // 3. group is deleted (==2)
            return this.filterGroupIds.selected.includes(task.GroupId) || 
                  !task.GroupId ||
                  task.group_Enabled === 0 ||
                  task.group_Enabled === 2;
        } else {
            // Only include tasks:
            // 1. group is active(==1)
            return this.filterGroupIds.selected.includes(task.GroupId) &&
                   task.group_Enabled === 1;
        }
    });

    //Filter the already group-filtered data based on AgentId and agent_Enabled
    let agentFilteredData = groupFilteredData.filter(task => {
        console.log("Task Enabled:", task.agent_Enabled, "Task AgentId:", task.AgentId);

        if (this.filterAgentIdsIncludeUnassigned) {
            // Include tasks:
            // 1.AgentId is unassigned (undefined or null), or
            // 2.agent_Enabled is 0, null, or undefined
            return (this.filterAgentIds.selected.includes(task.AgentId) || 
                    !task.AgentId || 
                    task.agent_Enabled === 0 || 
                    task.agent_Enabled === null || 
                    task.agent_Enabled === undefined);
        } else {
            // Only include tasks:
            // 1.AgentId is assigned and matches the selected AgentId, and
            // 2.agent_Enabled is not 0, null, or undefined
            return this.filterAgentIds.selected.includes(task.AgentId) &&
                  task.AgentId && 
                  task.agent_Enabled !== 0 && 
                  task.agent_Enabled !== null && 
                  task.agent_Enabled !== undefined;
        }
    });

    SdeskHelper.limitString(agentFilteredData, 50, true);
    // Return the final filtered data
    return agentFilteredData;
  }

  refreshParent() {
    this.refreshParentComponent.emit();
  }

  getData(showLoading = true){
    this.isLoading = showLoading;

    if(!this.requestId){
      this.tasksApiService.listAllTasks().then(response => {
        this.data = response;
        this.isLoading = false;
      })
    } else {
      this.tasksApiService.listRequestTasks(this.requestId).then(response => {
        this.data = response;
        this.isLoading = false;
      })
    }
  }

  create(){
    // Replace null with Component
    this.dialogService.createDialog(TasksDialogComponent, null, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  markComplete(id){
    this.tasksApiService.markComplete(id).then(response => this.getData(false))
  }

  markPending(id){
    this.tasksApiService.markPending(id).then(response => this.getData(false))
  }

  setAgent(id, agentId){
    this.tasksApiService.assignToAgent(id, agentId).then(response => console.log("updated"))
  }

  setGroup(taskId,groupID)
  {
    this.tasksApiService.assignToGroup(taskId, groupID).then(response => console.log("updated"))
  }

  filterAgents(task, index) {
    this.allTasksFiltered()[index].AgentId = "";
    this.apiAdminGroupsService.get(task.GroupId)
    .then(r => {
      this.options.agent = r.Members.map(item => {
        return { id: item.Id, name: item.Name }
      })
    });
  this.isLoading = false;
    this.setGroup(task.Id, task.GroupId)
  }

  filteragentsOnload(task) {
    this.isLoading = true;
    this.apiAdminGroupsService.get(task.GroupId)
      .then(r => {
        this.options.agent = r.Members.map(item => {
          return { id: item.Id, name: item.Name }
        })
      });
    this.isLoading = false;
    return this.options.agent
  }

  sendEmail(id, emailContents, requestId){


    this.requestApi.threadReply(requestId, emailContents, [], 1, null, false).then(response => {
      this.markComplete(id);
      this.refreshParent();
    });

  }


  edit(id){
    // Replace null with Component
    this.dialogService.createDialog(TasksDialogComponent, id, this.viewReference)
      .then(dialogSaved => {
        this.getData();
      }, dialogCancelled => {

      })
  }

  delete(id){
    this.dialogService.createDialog(ModalConfirmationComponent, 'Are you sure?', this.viewReference)
      .then(confirmed => {
        this.tasksApiService.delete(id).then(response => this.getData())
      }, cancelled => null)
  }

  toggleGroupsDropdown() {
    this.isGroupsDropdownVisible = !this.isGroupsDropdownVisible;
  }
  toggleAgentsDropdown() {
    this.isAgentsDropdownVisible = !this.isAgentsDropdownVisible;
  }

  isAllGroupsOptionsSelected(): boolean {
    return this.filterGroupIds.selected.length === this.options.group.length;
  }

  isAllAgentsOptionsSelected(): boolean {
    return this.filterAgentIds.selected.length === this.options.agent.length;
  }

  toggleSelectAll(selectAll: boolean, optionType: string): void {
    let selectionModel;
    let options;

    switch (optionType) {
      case 'groups':
        selectionModel = this.filterGroupIds;
        options = this.options.group;
        break;
      case 'agents':
        selectionModel = this.filterAgentIds;
        options = this.options.agent;
        break;
      default:
        return;
    }

    if (selectAll) {

      options.forEach(option => {
        selectionModel.select(option.id);
      });

      if(optionType === 'groups'){
        this.filterGroupIdsIncludeUnassigned = true;
      }

      if (optionType === 'agents') {
        this.filterAgentIdsIncludeUnassigned = true;
      }
    } else {

      options.forEach(option => {
        selectionModel.deselect(option.id);
      });

      if(optionType === 'groups'){
        this.filterGroupIdsIncludeUnassigned = false;
      }

      if (optionType === 'agents') {
        this.filterAgentIdsIncludeUnassigned = false;
      }
    }

    this.saveFilterStates();
  }

  saveFilterStates(): void {
    AllTasksComponent.savedGroupIds = [...this.filterGroupIds.selected];
    AllTasksComponent.savedAgentIds = [...this.filterAgentIds.selected];
    AllTasksComponent.savedAgentIdsIncludeUnassigned = this.filterAgentIdsIncludeUnassigned;
    AllTasksComponent.savedGroupIdsIncludeUnassigned = this.filterGroupIdsIncludeUnassigned;

    this.setCookie('savedGroupIds', JSON.stringify(AllTasksComponent.savedGroupIds), 365);
    this.setCookie('savedAgentIds', JSON.stringify(AllTasksComponent.savedAgentIds), 365);
    this.setCookie('savedAgentIdsIncludeUnassigned', JSON.stringify(AllTasksComponent.savedAgentIdsIncludeUnassigned), 365);
    this.setCookie('savedGroupIdsIncludeUnassigned', JSON.stringify(AllTasksComponent.savedGroupIdsIncludeUnassigned), 365);
  }


  setCookie(name: string, value: string, days: number) {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  getCookie(name: string) {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
